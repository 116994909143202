export const StringHelper = {
    renderMore: (body_text: string, max_len_str: number) => {
        const max_len = typeof max_len_str === "undefined" ? 150 : max_len_str;
        const body_len = body_text?.length ?? 0;

        return body_len > max_len
            ? `${body_text.substring(1, max_len)} ...`
            : body_text;
    },
    createSlug: (title_text: string) => {
        return title_text
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "");
    },

    encodeBase64: (text_str: string) => {
        return btoa(text_str);
    },

    decodeBase64: (text_str: string) => {
        return atob(text_str);
    },
};
