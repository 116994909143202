import React from 'react';

export type Thumbnail = {
    backgroundImage: string;
    rounded?: boolean;
};
export const Thumbnail = ({ backgroundImage, rounded = true }: Thumbnail) => {
    return <div
        className={`thumbnail ${rounded ? 'rounded' : ''}`}
        style={{ backgroundImage: `url(${backgroundImage ? backgroundImage : ''})` }} />;
};