import { Fragment } from "@app/model";
import React from "react";
import Doctors from "../common/Doctor";

const OurDoctors = ({ data }: { data: Fragment[] }) => {
    if (!data) return null;

    const normalizedData = data ?? [];

    return (
        <>
            <div className="row mb-3 our-doctor py-14 py-md-17">
                <div className="col-md-10 col-lg-12 col-xl-10 col-xxl-9 mx-auto text-center">
                    <h2 className="fs-15 text-uppercase text-primary mb-3">The Team</h2>
                    <h3 className="display-4 mb-7 px-lg-19 px-xl-18">Meet our Certified Doctors.</h3>
                </div>
            </div>
            <section className=" wrapper position-relative">
                <div className="container doctor-grid row gx-md-5 gy-5 mb-3">
                    {normalizedData.length > 0
                        ? normalizedData.map((doctor, index) => (
                            <Doctors data={doctor} key={index} />
                        ))
                        : <h4>Could not load our doctor list</h4>}
                </div>
            </section>
        </>
    );
};

export default OurDoctors;
