import React from 'react';
import ClampLines, { ClampLinesProps } from 'react-clamp-lines';

export type Clamp = Omit<ClampLinesProps, 'text'> & { text?: string; children: string };
export const BlockClamp = ({
    children,
    text,
    ...rest
}: Clamp) => {
    return <ClampLines
        text={children ?? text ?? ''}
        ellipsis="…"
        innerElement="div"
        buttons={false}
        {...rest}
    />;
};

export const ParagraphClamp = ({
    children,
    text,
    ...rest
}: Clamp) => {
    return <ClampLines
        text={children ?? text ?? ''}
        ellipsis="…"
        innerElement="p"
        buttons={false}
        {...rest}
    />;
};