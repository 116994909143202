import { MediaHelper } from '@app/helpers';
import React from 'react';
import Link from 'next/link';
import { Fragment } from '@app/model';

const Doctors = ({ data }: { data: Fragment }) => {
    return (
        <div className="col-md-6 col-lg-3">
            <div className="position-relative h-100">
                <div className="shape rounded bg-soft-blue rellax d-md-block" data-rellax-speed="0" style={{
                    bottom: '-0.5rem',
                    right: '-0.5rem',
                    width: '98%',
                    height: '98%',
                    zIndex: 0,
                }} />
                <div className="card h-100">
                    <figure className="card-img-top">
                        <img className="img-lfuid" src={data.photo && data.photo.id
                            ? MediaHelper.getUrl(data.photo.id)
                            : `/images/doctors/doctor1.jpg`} alt={data.title} />
                    </figure>
                    <div className="card-body px-6 py-5">
                        <h4 className="mb-1">
                            <Link href={data.slug ? `/doctor/${data.slug}` : `#`}>
                                <a href="#" className="hover link-primary">
                                    {data.title}
                                </a>
                            </Link>
                        </h4>
                        <div className="meta mb-2">{data.description}</div>
                        <p className="mb-0">{data.content}</p>
                    </div>
                    <div className="card-footer px-6 py-3">
                        <ul className="post-meta d-flex mb-0">
                            <li className="post-read-more ms-auto">
                                <Link href={data.slug ? `/doctor/${data.slug}` : `#`}>
                                    <a href="#" className="more hover link-primary">View Profile</a>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Doctors;
