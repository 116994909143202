import React from 'react';
import { MediaHelper } from '@app/helpers';
import { BlockClamp, Thumbnail } from '../atom';
import YouTube from 'react-youtube';
import { ASSETS_URL } from '@app/constants';
import ReactPlayer from 'react-player';
import { Resource } from '@app/model';

const ResourceView = ({ resourceData }: { resourceData: Resource }) => {
    const modalRef = React.createRef<HTMLDivElement>();
    const {
        id: index,
        resource_name,
        resource_type,
        resource_link,
        resource_file,
        featured_image,
    } = resourceData;
    const videoEmbedOptions = {
        height: '550',
        width: '100%',
    };

    if (resource_type === 'article') {
        return (
            <div className="pdf-item col-md-6 col-xl-4">
                <a href={resource_link ? resource_link : '#'}>
                    <div className="pdf-block card h-100">
                        <figure
                            className="card-img-top overlay overlay1 hover-scale border-bottom"
                            style={{ height: '14rem' }}
                        >
                            <span className="bg"></span>
                            <figcaption>
                                <h5 className="from-top mb-0">Read More</h5>
                            </figcaption>
                            <Thumbnail
                                rounded={false}
                                backgroundImage={
                                    `${ASSETS_URL}/${featured_image}` ||
                                    `/images/blog/blog1.jpg`
                                }
                            />
                        </figure>
                        <div className="card-body p-4 p-md-4">
                            <div className="post-header">
                                <h4 className="post-title m-0 text-hover">
                                    <BlockClamp id={`${index}-video`} className="" lines={2}>
                                        {resource_name ?? ''}
                                    </BlockClamp>
                                </h4>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        );
    }

    if (resource_type === 'pdf') {
        return (
            <div className="pdf-item col-md-6 col-xl-4">
                <div className="pdf-block card h-100">
                    <figure
                        className="card-img-top overlay overlay1 hover-scale border-bottom"
                        style={{ height: '14rem' }}
                        data-bs-toggle="modal"
                        data-bs-target={`#video-modal-${index}`}
                    >
                        <span className="bg"></span>
                        <figcaption>
                            <h5 className="from-top mb-0">Read More</h5>
                        </figcaption>
                        <Thumbnail
                            rounded={false}
                            backgroundImage={
                                `${ASSETS_URL}/${featured_image}` ||
                                `/images/blog/blog1.jpg`
                            }
                        />
                    </figure>
                    <div className="card-body p-4 p-md-4">
                        <div className="post-header">
                            <h4 className="post-title m-0">
                                <BlockClamp id={`${index}-video`} lines={2}>
                                    {resource_name ?? ''}
                                </BlockClamp>
                            </h4>
                        </div>
                    </div>
                </div>
                {/* Modal */}
                <div
                    ref={modalRef}
                    className="modal fade"
                    id={`video-modal-${index}`}
                    tabIndex={-1}
                >
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content text-center">
                            <div className="modal-header">
                                <button
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                                <div className="popup-title">
                                    {resource_name}{' '}
                                    <a
                                        className="hover fw-normal "
                                        href={`${ASSETS_URL}/${resource_file}#view=FitH`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        [ PDF ]
                                    </a>
                                </div>
                            </div>
                            <div
                                className="modal-body p-0"
                                style={{ lineHeight: 0 }}
                            >
                                <iframe
                                    width="100%"
                                    style={{ minHeight: '75vh' }}
                                    src={`${ASSETS_URL}/${resource_file}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (resource_type === 'video') {
        return (
            <div className="video-item col-md-6 col-xl-4">
                <div className="video-block card h-100 card-hover">
                    <div className="card-img-top">
                        <figure className="position-relative card-img-top overflow-hidden">
                            <span
                                className="btn btn-circle btn-primary btn-play ripple mx-auto mb-6 lightbox position-absolute"
                                style={{
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%,-50%)',
                                    zIndex: 3,
                                }}
                                data-bs-toggle="modal"
                                data-bs-target={`#video-modal-${index}`}
                            >
                                <i className="icn-caret-right" />
                            </span>
                            <Thumbnail
                                rounded={false}
                                backgroundImage={
                                    `${ASSETS_URL}/${featured_image}` ||
                                    `/images/blog/blog1.jpg`
                                }
                            />
                        </figure>
                    </div>
                    <div className="card-body p-4 p-md-4">
                        <div className="post-header">
                            <h4 className="post-title m-0">
                                <BlockClamp id={`${index}-video`} lines={2}>
                                    {resource_name ?? ''}
                                </BlockClamp>
                            </h4>
                        </div>
                    </div>
                </div>
                {/* Modal */}
                <div
                    ref={modalRef}
                    className="modal fade "
                    id={`video-modal-${index}`}
                    tabIndex={-1}
                >
                    <div
                        className="modal-dialog modal-dialog-centered modal-md"
                        style={{ minWidth: '55vw' }}
                    >
                        <div className="modal-content text-center">
                            <div className="modal-header">
                                <button
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                                <div className="display-6">{resource_name}</div>
                            </div>
                            <div className="modal-body p-0">
                                <ReactPlayer
                                    url={`${ASSETS_URL}/${resource_file}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (resource_type === 'youtube_link') {
        const videoId = MediaHelper.getYouTubeVideoId(resource_link ?? '');

        return (
            <div className="youtube-item col-md-6 col-xl-4">
                <div className="youtube-block card h-100">
                    <div className="card-img-top">
                        <figure
                            className="position-relative card-img-top overflow-hidden"
                            style={{ height: '14rem' }}
                        >
                            <span
                                className="btn btn-circle btn-primary btn-play ripple mx-auto mb-6 lightbox position-absolute"
                                style={{
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%,-50%)',
                                    zIndex: 3,
                                }}
                                data-bs-toggle="modal"
                                data-bs-target={`#video-modal-${index}`}
                            >
                                <i className="icn-caret-right" />
                            </span>
                            <Thumbnail
                                rounded={false}
                                backgroundImage={
                                    `${ASSETS_URL}/${featured_image}` ||
                                    `/images/blog/blog1.jpg`
                                }
                            />
                        </figure>
                    </div>
                    <div className="card-body p-4 p-md-4">
                        <div className="post-header">
                            <h4 className="post-title m-0">
                                <BlockClamp id={`${index}-video`} lines={2}>
                                    {resource_name ?? ''}
                                </BlockClamp>
                            </h4>
                        </div>
                    </div>
                </div>
                {/* Modal */}
                <div
                    className="modal fade "
                    id={`video-modal-${index}`}
                    tabIndex={-1}
                >
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content text-center rounded overflow-hidden">
                            <div className="modal-header">
                                <button
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                                <div className="display-6">{resource_name}</div>
                            </div>
                            <div
                                className="modal-body p-0 "
                                style={{ lineHeight: 0 }}
                            >
                                <YouTube
                                    videoId={videoId}
                                    opts={videoEmbedOptions}
                                    className="embed-responsive-item"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

const ResourcesSection = ({ resourcesData = [] }: { resourcesData: Resource[] }) => {
    if (resourcesData.length === 0) return null;

    return (
        <div className="container text-center">
            <div className="row">
                <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                    <h3 className="display-4 mb-8">Resources</h3>
                </div>
            </div>

            <div className="position-relative">
                <div
                    className="shape rounded-circle bg-soft-blue rellax w-16 h-16"
                    data-rellax-speed="1"
                    style={{
                        bottom: '-0.5rem',
                        right: '-2.2rem',
                        zIndex: 0,
                    }}
                ></div>
                <div
                    className="shape bg-dot blue rellax w-16 h-17"
                    data-rellax-speed="1"
                    style={{ top: '-0.5rem', left: '-2.5rem', zIndex: 0 }}
                ></div>
                <div className="row gx-md-5 gy-5 d-flex align-items-center justify-content-center">
                    {resourcesData.map((resource, index) => {
                        return (
                            <ResourceView resourceData={resource} key={index} />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ResourcesSection;
