import { BlockClamp, EditButton } from '@app/components/atom';
import { ASSETS_URL, CMSBaseURL, CMSCollection } from '@app/constants';
import { Fragment } from '@app/model';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export type AdvantageSectionProps = {
    title: string;
    description?: string;
    advantagesList: Fragment[];
    editMode: boolean;
};

export const AdvantageSection = (props: AdvantageSectionProps) => {
    const [activatedSection, setActivatedSection] = useState<number | null>(null);
    const { title, description, advantagesList = [], editMode } = props;
    const isSm = useMediaQuery({ maxWidth: 768 });

    useEffect(() => {
        const outsideClickListener = (
            event: React.MouseEvent<HTMLElement, MouseEvent>
        ) => {
            if (
                !(event.target as HTMLElement).closest('.advantage-active') &&
                activatedSection !== null
            ) {
                setActivatedSection(null);
            }
        };

        const removeClickListener = () => {
            document.removeEventListener<any>('click', outsideClickListener);
        };

        document.addEventListener<any>('click', outsideClickListener);

        return () => removeClickListener();
    }, [activatedSection]);

    if (advantagesList?.length == 0) return null;

    return (
        <>
            <div className="container mx-auto text-center mb-6">
                <h3 className="display-4 mb-3">{title}</h3>
                {description && (
                    <div className="lead fs-lg mb-0 pt-2">{description}</div>
                )}
            </div>
            <div className="container position-relative advantage-list py-md-10 pb-md-0 mt-md-n10 mx-auto">
                <div className="advantage-block-grid row gx-md-5 gy-5 mb-3">
                    <div
                        className="shape bg-dot primary rellax w-16 h-17"
                        data-rellax-speed="1"
                        style={{ top: '-0.5rem', left: '-1.5rem', zIndex: 0 }}
                    />
                    <div
                        className="shape bg-soft-blue rounded-circle rellax w-16 h-16"
                        data-rellax-speed="1"
                        style={{
                            bottom: '-1.5rem',
                            right: '-1.5rem',
                            zIndex: 0,
                        }}
                    />
                    {advantagesList.map((advantage, index) => {
                        const isActivated =
                            activatedSection === advantage.id && !isSm;
                        const path = `${CMSBaseURL}/${CMSCollection.FRAGMENT}/${advantage.id}`;

                        return (
                            <div
                                key={index}
                                className="col-xl-3 col-lg-4 col-md-6 relative-wrap"
                            >
                                <div
                                    id={`${advantage.id}`}
                                    className={`advantage zooming-container h-100 position-relative ${isActivated ? 'advantage-active' : ''
                                    }`}
                                    onClick={() => {
                                        setActivatedSection((currentActiveId) =>
                                            currentActiveId === advantage.id
                                                ? null
                                                : advantage.id
                                        );
                                    }}
                                >
                                    <div
                                        className={`post-item card card-hover`}
                                    >
                                        <a className={`d-block post-thumbnail`}>
                                            <figure
                                                className="card-img-top overlay overlay1 position-relative"
                                                style={{ height: '8rem' }}
                                            >
                                                <section
                                                    className="post-thumbnail h-100 wrapper image-wrapper bg-image bg-full bg-overlay text-white p-2 d-flex align-items-center"
                                                    style={{
                                                        backgroundSize: 'cover',
                                                    }}
                                                    data-image-src={`${ASSETS_URL}/${advantage.photo?.id}`}
                                                />
                                                <h2 className="position-absolute post-title d-flex flex-column w-100 p-4 align-items-center text-center">
                                                    <BlockClamp
                                                        id={`${advantage.id}-post-title`}
                                                        lines={3}
                                                    >
                                                        {advantage?.title ??
                                                            `Missing Title`}
                                                    </BlockClamp>
                                                </h2>
                                            </figure>
                                        </a>
                                        <div className="card-body p-4">
                                            <div className="post-content ">
                                                {isActivated || isSm ? (
                                                    advantage?.description ??
                                                    'Missing description'
                                                ) : (
                                                    <div className={!isSm ? 'line-clamp' : ''}>
                                                        {advantage?.description}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {editMode && <EditButton path={path} padding='4px 20px 0 0' />}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
