import React from 'react';
import Link from 'next/link';
import Moment from 'react-moment';
import { MediaHelper, StringHelper } from '@app/helpers';
import { BlockClamp, ParagraphClamp } from '../atom';
import { Post } from '@app/model';

const PostBlock = ({ data }: { data: Post }) => {
    if (!data) return null;

    return (
        <div className="card h-100">
            <figure className="card-img-top overlay overlay1 hover-scale">
                <Link href={data.slug ? `/post/${data.slug}` : `#`}>
                    <a href="#">
                        <img src={data?.photo?.id
                            ? MediaHelper.getUrl(data.photo?.id)
                            : `/images/blog/blog1.jpg`} alt={data.title ?? `Article Image`} />
                    </a>
                </Link>
                <figcaption>
                    <h5 className="from-top mb-0">Read More</h5>
                </figcaption>
            </figure>
            <div className="card-body">
                <div className="post-header">
                    <h2 className="post-title h3 mt-1 mb-3">
                        <Link href={data.slug ? `/post/${data.slug}` : `#`}>
                            <a className="link-dark">
                                <BlockClamp id={`${data.id}-title`} lines={3}>{data.title}</BlockClamp>
                            </a>
                        </Link>
                    </h2>
                </div>
                <div className="post-content">
                    {data.description
                        ? <ParagraphClamp id={`${data.id}-excerpt`} lines={4}>
                            {StringHelper.renderMore(data.description, 150)}
                        </ParagraphClamp>
                        : <i className="text-secondary">No description available</i>}
                </div>
            </div>
            <div className="card-footer">
                <ul className="post-meta d-flex mb-0">
                    <li className="post-date">
                        <i className="uil uil-calendar-alt"></i>
                        <span><Moment format="MM/DD/YYY">
                            {data.created_on ?? 0}
                        </Moment></span>
                    </li>
                    <li className="post-read-more ms-auto liststyle-none">
                        <Link href={data.slug ? `/post/${data.slug}` : `#`}>
                            <a href="#" className="more hover link-primary">Learn More</a>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default PostBlock;
