import { AdvantageSection } from '@app/@gotecq-cms/components/advantage-section';
import NoContent from '@app/components/common/NoContent';
import AboutSection from '@app/components/home/AboutSection';
import Hero from '@app/components/home/Hero';
import HowWeHelp from '@app/components/home/HowWeHelp';
import LatestBlogPost from '@app/components/home/LatestBlogPost';
import OurDoctors from '@app/components/home/OurDoctors';
import ResourcesSection from '@app/components/home/Resources';
import { useAppData } from '@app/hooks';
import { Fragment, Post, Resource, Site } from '@app/model';
import { CMS } from '@app/service';
import { NextSeo } from 'next-seo';
import React from 'react';

type IndexProps = {
    home_sections: { [key: string]: Fragment[] };
    site_data: Site;
    latest_articles: Post[];
    resources: Resource[];
}

const Index = ({
    home_sections,
    site_data,
    latest_articles,
    resources,
}: IndexProps) => {
    const { isPreviewSite } = useAppData();

    return (
        <>
            <NextSeo
                title={site_data.name}
                description={site_data.description ? site_data.description : ''}
            />
            {Object.keys(home_sections).length === 0 ? (
                <NoContent />
            ) : (
                ''
            )}
            <Hero
                slides={home_sections?.home_slide}
                site_info={site_data}
            />
            <main id="mains">
                <section id="howwehelp" className="wrapper home-section ">
                    <HowWeHelp data={home_sections?.how_we_help} editMode={isPreviewSite} />
                </section>
                <section id="advantage" className="wrapper home-section ">
                    <AdvantageSection
                        advantagesList={home_sections?.advantage}
                        title="The TECQ Foundation Advantage"
                        description="TECQ Foundation provides many advantages that will benefit
                        providers and patients alike."
                        editMode={isPreviewSite}
                    />
                </section>
                <section id="resources-section" className="wrapper home-section ">
                    <ResourcesSection resourcesData={resources} />
                </section>
                <section id="join-tecq-partners" className="wrapper home-section">
                    <AboutSection data={home_sections?.['home_about_us']} editMode={isPreviewSite} />
                </section>
                <OurDoctors data={home_sections.our_team} />

                <LatestBlogPost data={latest_articles} />
            </main>
        </>
    );
};

export async function getStaticProps() {
    const home_sections = await CMS.getHomePageSections();
    const latest_articles = await CMS.getLatestArticles();
    const resources = await CMS.getAllResource();

    return {
        props: {
            home_sections,
            latest_articles,
            resources
        },
    };
}

export default Index;
