import React from 'react';

const NoContent = () => {
    return (
        <section id="about" className="about">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-21 no-content">
                        <h1>This category does not have any contents yet.</h1>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NoContent;
