import { CMSBaseURL, CMSCollection } from '@app/constants';
import { MediaHelper } from '@app/helpers';
import { Fragment } from '@app/model';
import React from 'react';
import { EditButton } from '../atom';

const HowWeHelp = ({ data, editMode }: { data: Fragment[]; editMode: boolean }) => {
    if (!data || !data?.length) return null;

    const meshImageDistribution = [
        'position-relative col-4 d-xl-block d-none offset-md-2 align-self-end',
        'position-relative col-12 col-xl-6 align-self-end',
        'position-relative col-12 col-xl-6 offset-md-1',
        'position-relative col-4 d-xl-block d-none align-self-start'
    ];

    return (
        <div className="container">
            <div className="row gy-14 gx-lg-12 align-items-center">
                <div className="col-xl-5 col-lg-3 col-0 d-none d-lg-block position-relative">
                    <div className="row position-relative gx-md-5 gy-5">
                        {data.slice(0, 4)
                            .map((data) => data.photo?.id
                                ? MediaHelper.getUrl(data.photo?.id)
                                : null)
                            .filter(Boolean)
                            .map((photoId: string | null, index) => {
                                return <div key={photoId} className={meshImageDistribution[index]} data-cue="fadeIn">
                                    {index === 1 && <div
                                        className="shape rounded bg-soft-primary rellax d-md-block" data-rellax-speed="0" style={{
                                            bottom: '0.9rem', right: '-0.75rem', width: '85%', height: '100%',
                                        }} />}
                                    {index === 2 && <div
                                        className="shape rounded bg-soft-yellow rellax d-md-block" data-rellax-speed="0" style={{
                                            bottom: '-0.9rem', right: '-0.75rem', width: '85%', height: '100%',
                                        }} />}
                                    <figure className="rounded">
                                        <img src={photoId ? photoId : ''} alt={`Image ${index}`} />
                                    </figure>
                                </div>;
                            })}
                    </div>
                </div>
                <div className="how-we-help-list col-xl-7 col-lg-9 col-12" style={{
                    textAlign: 'justify',
                }}>
                    <h3 className="display-4 mb-6 d-block d-md-none text-center">How We Help</h3>
                    <h3 className="display-4 mb-7 d-md-inline-block d-none" id='title-md'>How We Help</h3>
                    {data
                        .sort((l, r) => l.id - r.id)
                        .map((item) => {
                            const path = `${CMSBaseURL}/${CMSCollection.FRAGMENT}/${item.id}`;
                            return <div key={item.id} className="d-flex flex-row mb-6 relative-wrap">
                                <div>
                                    <h4 className="mb-1">{item.title ?? `Missing Title`}</h4>
                                    <p className="mb-0">{item.description ?? `Missing Description`}</p>
                                </div>
                                {editMode && <EditButton path={path} />}
                            </div>;
                        }
                        )}
                </div>
            </div>
        </div>
    );
};

export default HowWeHelp;
