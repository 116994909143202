import React from 'react';
import ReactMarkdown from 'react-markdown';
import { MediaHelper } from '@app/helpers';
import { Fragment } from '@app/model';
import { EditButton } from '../atom';
import { CMSBaseURL, CMSCollection } from '@app/constants';

const AboutSection = ({ data, editMode }: { data: Fragment[]; editMode?: boolean }) => {
    if (!data || !data?.length) return null;

    const about_us_section = data[0] ?? false;
    const src = about_us_section.photo
        ? MediaHelper.getUrl(
            about_us_section.photo.id
        )
        : `/img/about.jpg`;
    const path = `${CMSBaseURL}/${CMSCollection.FRAGMENT}/${about_us_section.id}`;
    return (
        <section className="container">
            <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                <div className="col-8 col-lg-6 mx-auto position-relative">
                    <div className="shape bg-soft-primary d-lg-block d-none rounded-circle rellax w-20 h-20" data-rellax-speed="1" style={{
                        top: '-1rem',
                        right: '0',
                    }} />
                    <figure className="rounded"><img src={src} alt="" /></figure>
                </div>
                <div className="col-12 col-lg-6 relative-wrap">
                    <h2 className="display-4 mb-6 text-center" >{about_us_section.title}</h2>
                    <div className="content-markdown-wrapper">
                        <ReactMarkdown
                            children={about_us_section?.content ?? ''}
                            skipHtml={true}
                        />
                    </div>
                    {editMode && <EditButton path={path} padding='0.3rem 1rem 0 0' />}
                </div>
            </div>
        </section>
    );
};

export default AboutSection;
