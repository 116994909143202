import React from 'react';
import { MediaHelper } from '@app/helpers';
import ReactMarkdown from 'react-markdown';
import { Fragment, Site } from '@app/model';
import Link from 'next/link';

const Hero = ({ slides }: { slides: Fragment[]; site_info: Site }) => {
    if (!slides || !slides.length) return null;

    const show_controller = slides?.length > 1;
    const textOverlayPositionClass = [
        'px-0 col-md-10 col-lg-6 col-xl-6 col-xxl-4 offset-md-1 offset-lg-5 offset-xl-6 offset-xxl-6 mt-xxl-auto pb-xxl-10  pb-xxl-8 text-center text-lg-start',
        'px-0 col-md-10 col-lg-6 col-xl-6 col-xxl-4 offset-md-1 offset-lg-2 mb-lg-auto pt-lg-14 text-center text-lg-start',
        'px-0 col-md-10 col-lg-6 col-xl-6 col-xxl-5 offset-md-1 offset-lg-4 offset-xl-5 offset-xxl-5 text-center text-lg-start mt-md-auto pb-md-14',
        'px-0 col-md-10 col-lg-6 col-xl-6 col-xxl-5 offset-md-1 offset-lg-3 text-center text-lg-start mt-md-auto mb-md-5 pb-xxl-5',
        'px-0 col-md-10 col-lg-6 col-xl-6 col-xxl-4 offset-md-1 my-auto offset-lg-0 text-center text-lg-start pb-xxl-10 hidden-heading',
        'px-0 col-md-10 col-lg-6 col-xl-6 col-xxl-4 offset-md-1 my-auto offset-lg-0 mt-lg-20 text-center text-lg-start',
    ];
    const sliceIndexBackgroundPosition = {
        4: '80% top'
    };

    return (
        <div className="hero-slider-wrapper bg-dark" id="home-slide">
            <div
                className="hero-slider owl-carousel dots-over owl-carousel-homepage custom-overlay-bg"
                data-nav={`${show_controller}`}
                data-dots={`${show_controller}`}
                data-autoplay="true"
            >
                {slides.map((slide, index) => {
                    return (
                        <div
                            className="owl-slide d-flex align-items-center"
                            style={{
                                backgroundImage: `url(${MediaHelper.getUrl(
                                    slide.photo ? slide.photo.id : ''
                                )})`,
                                backgroundSize: 'cover',
                                backgroundPosition:
                                    sliceIndexBackgroundPosition[index as keyof typeof sliceIndexBackgroundPosition] ?? 'top',
                                backgroundRepeat: 'no-repeat',
                            }}
                            key={index}
                        >
                            <div className="container p-12 p-xxl-0 h-100 d-flex align-items-center">
                                <div className="row h-100">
                                    <div
                                        className={
                                            textOverlayPositionClass[index]
                                        }
                                    >
                                        <h2
                                            className="display-1 fs-56 mb-4 animated-caption"
                                            data-anim="animate__slideInDown"
                                            data-anim-delay="500"
                                        >
                                            {slide.title}
                                        </h2>
                                        <div
                                            className="lead fs-23 lh-sm mb-7 animated-caption markdown-content-wrapper slide-markdown-wrapper"
                                            data-anim="animate__slideInRight"
                                            data-anim-delay="1000"
                                        >
                                            <ReactMarkdown>
                                                {slide.content ? slide.content : slide.description ? slide.description : ''}
                                            </ReactMarkdown>
                                        </div>
                                        {slide.link && (
                                            <div
                                                className="animated-caption"
                                                data-anim="animate__slideInUp"
                                                data-anim-delay="1500"
                                            >
                                                <a
                                                    href={slide.link}
                                                    className="btn btn-lg btn-outline-white rounded-pill"
                                                >
                                                    Read More
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className='text-center py-2 position-absolute section-join-us'>
                <Link href="/page/join-tecq-foundation">
                    <a className="btn btn-soft-primary join-us-button" >
                        Join TECQ Foundation
                    </a>
                </Link>
            </div>
        </div>
    );
};
export default Hero;
