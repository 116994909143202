import { Post } from '@app/model';
import React from 'react';
import PostBlock from '../common/PostBlock';

const LatestBlogPost = ({ data }: { data: Post[] }) => {
    if (!data.length) return null;

    return (
        <>
            <div className="row text-center">
                <div className="col-lg-9 col-xl-8 col-xxl-7 mx-auto">
                    <h2 className="fs-15 text-uppercase text-primary mb-3">Latest Articles</h2>
                    <h3 className="display-4 mb-6">Check out our articles about the most relevant health topics.</h3>
                </div>
            </div>
            <div className="position-relative">
                <div
                    className="shape bg-dot primary rellax w-17 h-20"
                    data-rellax-speed="1"
                    style={{ top: 0, left: '-1.7rem', zIndex: 0 }}
                />
                <div className="post-block-grid row gx-md-5 gy-5 mb-3">
                    {data.map((post) => (
                        <article className="post-block col-md-6 col-lg-4" key={post?.id}>
                            <PostBlock data={post} key={post.id} />
                        </article>
                    ))}
                </div>
            </div>
        </>
    );
};

export default LatestBlogPost;
